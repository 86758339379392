<template>
  <div>
    <vue-headful 
      :title="seo[$i18n.locale].title" 
      :description="seo[$i18n.locale].description"
    />

    <!-- <div v-if="showModal" class="modal">
      <div class="modal-content">
        <span class="close" @click="closeModal">&times;</span>
      </div>
    </div> -->
    
    <main-banner :slides="slides.list"/>
    
    <featured-products/>
    
    <featured-content-1
      v-if="lastNew.content && lastNew.content.uid"
      :title="$t('menu.stayInTouch')"
      :mainTitle="$t('menu.news')"
      :content="lastNew.content"
    />
    
    <div class="mb-xxs-15"></div>

    <featured-content-2
      v-if="recipes.list.length > 0"
      :title="$t('menu.recipes')"
      :contentList="recipes.list"
    />

  </div>
</template> 

<script>
import store from "../store";
import MainBanner from "../components/MainBanner/MainBanner";
import FeaturedProducts from "../components/FeaturedProducts/FeaturedProducts";
import FeaturedContent1 from "../components/FeaturedContent1/FeaturedContent1";
import BoxNewsAndRecipes from "../components/BoxNewsAndRecipes/BoxNewsAndRecipes";
import FeaturedContent2 from "../components/FeaturedContent2/FeaturedContent2";
import { initChat } from "../utils/chat/chatInit.js";

export default {
  name: "home-page",
  components: {
    MainBanner,
    FeaturedProducts,
    FeaturedContent1,
    //eslint-disable-next-line
    BoxNewsAndRecipes,
    FeaturedContent2
  },
  data: function() {
    return {
      slides: {
        list: []
      },
      lastNew: {
        content: {}
      },
      recipes: {
        list: []
      },
      showNews: false,
      showRecipes: false,
      showModal: false,
      seo: {
        pt: {
          title: 'Neugebauer Chocolates',
          description: 'A primeira fábrica de chocolates do Brasil que produz confeitos, bombons, chicles, pirulitos e doces, além do autêntico chocolate Neugebauer.'
        },
        en: {
          title: 'Neugebauer Chocolates',
          description: 'The first chocolate factory in Brazil which produces confectionery, bonbons, chewing gums, lollipops and sweets besides the authentic Neugebauer’s chocolate.'
        },
        es: {
          title: 'Neugebauer Chocolates',
          description: 'La primera fábrica de chocolates de Brasil que produce confitería, bombones, chicles, chupetines y dulces, además del auténtico chocolate Neugebauer.'
        },
      }
    };
  },
  
  created: function() {
    this.lastNew.content = store.state.pageData.lastPost;
    this.slides.list = store.state.pageData.banners.slides;
    this.recipes.list = store.state.pageData.recipes.recipes;
    //setTimeout(() => {
    //  this.showModal = true;
    //  document.querySelector('.main-header').style.zIndex = '3';
    //}, 1000);
  },
  methods: {
    closeModal() {
      this.showModal = false;
      document.querySelector('.main-header').style.zIndex = '4';
    }
  },
  mounted() {
    initChat();
  },
};
</script>

<style>
.modal {
  position: fixed; 
  z-index: 99999999;
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgb(0,0,0); 
  background-color: rgba(0,0,0,0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  z-index: 101;
  width: 100%;
  max-width: 713px;
  height:630px;
  background-color: transparent;
  background-image: url('../assets/imgs/banner_rs.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.close {
  color: #ffffff;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

@media (max-width: 768px) {
  .modal-content {
    width: 85%;
    height: 320px;
    background-size: contain;
    transform: translate(-50%, -50%);
  }

}
</style>