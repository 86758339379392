<template>
  <div v-if="!showLoading" class="main-footer bg-yellow">
    <div class="row">
      <div class="col xxs-14 sm-12 offset-xxs-1 mb-xxs-7 mb-sm-5 mb-md-4 mb-lg-3 mb-xlg-3">
        <NeugebauerLogo class="logo"/>
        <a href="https://www.dnvgl.com.br/" target="_blank">
          <img :src="selo[$i18n.locale].img" class="selo" alt="Selo DNV-GL">
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col xxs-14 sm-5 md-4 lg-4 xlg-3 mb-xxs-9 mb-sm-0 offset-xxs-1 footer-text">
        <div class="muito-mais f-brown">{{ $t('menu.moreFlavor1') }}</div>
        <div class="sabor f-white">{{ $t('menu.moreFlavor2') }}</div>
        <div class="para-voce f-white">{{ $t('menu.moreFlavor3') }}</div>
        <div class="vertical-line hide-xxs show-sm"></div>
      </div>
      <nav class="col xxs-14 sm-4 md-3 offset-xxs-1 mb-xxs-12 mb-sm-0">
        <ul class="nav-list">
          <li>
            <router-link :to="`/${this.$i18n.locale}/a-marca`">
              <span>{{ $t('menu.brand') }}</span>
            </router-link>
          </li>
          <li>
            <router-link :to="`/${this.$i18n.locale}/novidades`">
              <span>{{ $t('menu.news')}}</span>
            </router-link>
          </li>
          <li>
            <router-link :to="`/${this.$i18n.locale}/receitas`">
              <span>{{ $t('menu.recipes')}}</span>
            </router-link>
          </li>
          <li>
            <router-link :to="`/${this.$i18n.locale}/utilidades`">
              <span>{{ $t('menu.utilities')}}</span>
            </router-link>
          </li>
          <li>
            <router-link :to="`/${this.$i18n.locale}/fale-conosco`">
              <span>{{ $t('menu.contact')}}</span>
            </router-link>
          </li>
          <li>
            <a :href="`https://www.contatoseguro.com.br/pt/neugebauer/`" target="_blank"><span>Canal de Integridade</span></a>
          </li>
          <!-- <li>
            <router-link :to="`/${this.$i18n.locale}/quero-revender`">
              <span>{{ $t('menu.resell')}}</span>
            </router-link>
          </li> -->
          <li v-if=" $i18n.locale == 'pt' ">
            <a :href="`https://neugebauer.gupy.io/`" target="_blank"><span>{{ $t('menu.work')}}</span></a>
          </li>
          <li>
            <router-link :to="`/${this.$i18n.locale}/politica-de-privacidade`">
              <span>{{ $t('menu.policy')}}</span>
            </router-link>
          </li>
          <li>
            <router-link :to="`/${this.$i18n.locale}/politica-de-cookies`">
              <span>{{ $t('menu.cookies')}}</span>
            </router-link>
          </li>
        </ul>
      </nav>
      <div class="col xlg-3 xxs-9 sm-5 md-4 offset-xlg-5 offset-md-3 offset-xxs-1 offset-sm-0">
        <div class="sac-text mb-sm-4 mb-xlg-12 mb-xxs-10">
          <div class="f-brown" v-html="sac[$i18n.locale].title"></div>
          <div class="f-white">0800 051 7542</div>
        </div>
        <div class="social-network">
          <div class="text f-brown mb-sm-5 mb-xlg-8 mb-xxs-12">
            {{ $t('menu.followUs1')}}
            <br>
            {{ $t('menu.followUs2')}}
          </div>
          <ul class="icons">
            <li>
              <a href="https://www.facebook.com/QueroNeugebauer/" target="_blank">
                <FacebookIcon/>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/queroneugebauer/" target="_blank">
                <InstagramIcon/>
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/neugebauersa/" target="_blank">
                <LinkedinIcon/>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- <a class="demonietto-icon" href="http://www.perverte.me/pt" target="_blank">
      <img :src="demoniettoIcon">
    </a> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import NeugebauerLogo from "../../assets/imgs/logo-neugebauer.svg";
import FacebookIcon from "../../assets/imgs/fb-icon.svg";
import InstagramIcon from "../../assets/imgs/ig-icon.svg";
import LinkedinIcon from "../../assets/imgs/in-icon.svg";
import DemoniettoIcon from "../../assets/imgs/demonietto-icon.png";
import SeloPt from "../../assets/imgs/selo-pt.png";
import SeloEnEs from "../../assets/imgs/selo-en-es.png";

export default {
  name: "main-footer",
  components: {
    NeugebauerLogo,
    // SeloPt,
    // SeloEnEs,
    FacebookIcon,
    InstagramIcon,
    LinkedinIcon
  },
  computed: mapState(["showLoading"]),
  data() {
    return {
      demoniettoIcon: DemoniettoIcon,
      selo: {
          pt: {
              img: SeloPt
          },
          en: {
              img: SeloEnEs
          },
          es: {
              img: SeloEnEs
          }
      },
      sac: {
        pt: {
              title: 'FALE CONOSCO'
          },
          en: {
              title: 'CUSTOMER SERVICE'
          },
          es: {
              title: 'SERVICIO DE ATENCIÓN AL CLIENTE'
          }
      },
    }
  }
};
</script>

<style lang="sass" scoped>
@import '../../assets/sass/neug-grid/_variables'

.selo
  width: 90px
  height: 86px
  filter: invert(1)

.main-footer
    position: relative
    z-index: 2
    padding: 15% 0

    @media ($xsBreak)
        padding: 13% 0

    @media ($smBreak)
        padding: 10% 0

    @media ($mdBreak)
        padding: 5% 0

    .demonietto-icon
        position: absolute
        bottom: 0
        right: 6.3%

    .logo
        fill: #FFFFFF
        width: 150px
        height: auto

        @media ($smBreak)
            width: 30%

        @media ($mdBreak)
            width: 22%

        @media ($xlgBreak)
            width: 13%

    .selo
      margin-left: 100px

    .footer-text
        font-family: 'Block BE'
        font-weight: bold
        text-transform: uppercase
        line-height: 0.9
        position: relative

    .vertical-line
        position: absolute
        top: 50%
        transform: translateY(-50%)
        height: 95%
        border-left: 1px solid #f9b461

        @media ($smBreak)
            right: -11%

        @media ($xlgBreak)
            right: -17%

    .muito-mais
        font-size: 20px

        @media ($smBreak)
            font-size: 3.2vw

        @media ($mdBreak)
            font-size: 2.5vw

        @media ($xlgBreak)
            font-size: 2vw

    .sabor
        font-size: 55px

        @media ($smBreak)
            font-size: 9.1vw

        @media ($mdBreak)
            font-size: 7.3vw

        @media ($xlgBreak)
            font-size: 5.7vw

    .para-voce
        color: #fbe6d5
        font-size: 25px

        @media ($smBreak)
            font-size: 4.3vw

        @media ($mdBreak)
            font-size: 3.5vw

        @media ($xlgBreak)
            font-size: 2.8vw
        

    .nav-list
        padding: 0

        @media ($smBreak)
            margin-top: -2.6%

        @media ($mdBreak)
            margin-top: 0

        li
            text-transform: uppercase

            @media ($smBreak)
                font-size: 12px

            @media ($mdBreak)
                font-size: 11px

            @media ($lgBreak)
                font-size: 12px
                line-height: 1.76vw

            @media ($xlgBreak)
                line-height: 1.45vw

            a
                text-decoration: none
                color: #FFFFFF
                font-weight: 600
                position: relative
                transition: all 0.5s ease

                &:hover
                    color: #6b3128

                    &:after
                        background-color: #FFFFFF

                span
                    position: relative
                    z-index: 3

                &:after
                    content: ''
                    position: absolute
                    top: -4px
                    left: -3px
                    padding: 3px
                    background-color: transparent
                    width: 100%
                    height: 100%
                    transition: all 0.5s ease


    .sac-text
        font-family: 'Block BE'
        font-weight: bold
        text-transform: uppercase
        line-height: 1
        font-size: 23px
        
        @media ($smBreak)
            font-size: 3.2vw

        @media ($mdBreak)
            font-size: 2.8vw

        @media ($xlgBreak)
            font-size: 1.8vw

    .social-network
        .text
            text-transform: uppercase
            font-size: 12px
            line-height: 1.3

            @media ($lgBreak)
                font-size: 12px

        .icons
            display: flex
            width: 100%
            max-width: 125px
            justify-content: space-between

            li
                position: relative
                width: 16px
                padding-top: 16px

                @media ($xlgBreak)
                    width: 20px
                    padding-top: 20px

                svg
                    width: auto
                    height: 100%
                    position: absolute
                    top: 50%
                    left: 50%
                    transform: translate(-50%,-50%)
                    fill: #FFFFFF

    

</style>
